import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../config';
import { useAuthContext } from '../authentication/AuthContext';
import LandingNavigation from '../components/LandingNavigation';
import LandingFooter from '../components/LandingFooter';

const Login = () => {

    const { currentUser, updateCurrentUser } = useAuthContext();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    
    async function handleLogin(e) {
        e.preventDefault();

        if(!loading) {
            setLoading(true);
            setError('');

            try {
                let res = await axios.post(`${API_URL}/auth/login`, {
                    email: email.toLowerCase(),
                    password: password
                });

                if(res.data) {
                    updateCurrentUser(res.data);
                    history.push('/entry'); // refresh
                }

            } catch (error) {
                if(error.response) {
                    setError(error.response.data.message);
                } else {
                    setError('Cannot communicate with server');
                }
                setLoading(false);
            }
        }
    }

    // Press enter key to login
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleLogin(e);
        }
    }
    
    // If user lands on the login page while already logged in
    useEffect(() => {
        if (currentUser) {
            history.push('/home');
        }
    }, []);

    return !currentUser ? (
        <div className='login-screen'>
            <LandingNavigation active='login' />

            <main>
                <h2>Sign In</h2>

                <label>Email</label>
                <input type="text" value={email} onChange={e=>setEmail(e.target.value)} onKeyDown={e=>handleKeyDown(e)} />
                
                <label>Password</label>
                <input type="password" value={password} onChange={e=>setPassword(e.target.value)} onKeyDown={e=>handleKeyDown(e)} />
                
                <div className='form-action'>
                    <button disabled={loading} onClick={e=>handleLogin(e)}>
                        {loading ? 'Signing in...' : 'Sign In'}
                    </button>
                    <Link to={'/util/reset-password'}>
                        Forgot Password?
                    </Link>
                </div>

                {error ? (
                    <div className='form-notification error'>
                        {error}
                    </div>
                ) : ''}
            </main>

            <LandingFooter />
        </div>  
    ) : '';
}


export default Login;