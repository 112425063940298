import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthContext } from '../authentication/AuthContext';
import { API_URL } from '../config';
import { useParams } from 'react-router';
import Navigation from '../components/Navigation';
import { DAY, MONTH } from '../utility';

const PastEntry = () => {

    const { currentUser, getUserData } = useAuthContext();
    const uid = getUserData().id;

    const [font, setFont] = useState('');
    const [background, setBackground] = useState('');
    const [darkMode, setDarkMode] = useState(''); // get initial from localStorage?

    const { date } = useParams();
    const [dataLoading, setDataLoading] = useState(true);

    const dateObj = new Date(Date.parse(date));

    const [doc, setDoc] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [last, setLast] = useState('');
    const [twc, setTwc] = useState('');

    async function getPastEntry() {
        if(dataLoading) {
            try {
                let res = await axios.get(`${API_URL}/entry/past/${uid}/entry/${date}`,{
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': currentUser,
                    }
                })

                setDoc(res.data.entry);
                setStart(res.data.typingStart);
                setEnd(res.data.typingEnd);
                setLast(res.data.lastUpdated);
                setTwc(res.data.wordCount);
            } catch (error) {
                console.log(error);
            }
    
            setDataLoading(false);
        }
    }

    async function loadOptions() {
        axios.get(`${API_URL}/user/${uid}/options`, {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': currentUser,
            }
        }).then((res) => {
            setFont(res.data.font);
            setBackground(res.data.background);
            setDarkMode(res.data.darkMode);
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        getPastEntry();
        loadOptions();
    }, []);

    return (
        <div className={`past-entry-screen`} style={{
            background: background
        }}>
            <Navigation />

            <div className='container-tight'>

                <div className='entry-statistics'>
                    <div className='numbers'>
                        <div className='stat-item'>
                            <h5>{start ? new Date(parseInt(start)).getHours().toString().padStart(2, '0') + ':' + new Date(parseInt(start)).getMinutes().toString().padStart(2, '0') : '-'}</h5>
                            <p>Writing Started</p>
                        </div>
                        <div className='stat-item'>
                            <h5>{end ? new Date(parseInt(end)).getHours().toString().padStart(2, '0') + ':' + new Date(parseInt(end)).getMinutes().toString().padStart(2, '0') : '-'}</h5>
                            <p>Made it to 750</p>
                        </div>
                        <div className='stat-item'>
                            <h5>{last ? new Date(last).getHours().toString().padStart(2, '0') + ':' + new Date(last).getMinutes().toString().padStart(2, '0') : '-'}</h5>
                            <p>Final Save</p>
                        </div>
                        {/* <div className='stat-item'>
                            <h5>{(4423).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</h5>
                            <p>Peak WPM</p>
                        </div> */}
                        <div className='stat-item'>
                            <h5>{twc ? (twc).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-'}</h5>
                            <p>Total Words</p>
                        </div>
                    </div>
                </div>

                <h2>{`${DAY[dateObj.getDay()]}, ${MONTH[dateObj.getMonth()]} ${dateObj.getDate()}th, ${dateObj.getFullYear()}`}</h2>
                <div className={`view-entry font--${font}`} dangerouslySetInnerHTML={{__html: doc}} ></div>
            </div>
        </div>
    );
}

export default PastEntry;