import { Link } from 'react-router-dom';
import { useAuthContext } from '../authentication/AuthContext';

const LandingNavigation = (props) => {

    const { currentUser } = useAuthContext();

    return (
        <header className='dense'>
            <Link to={'/'} className='header-text subtle-link'>
                wordseveryday <sup>alpha</sup>
            </Link>
            <div className='header-action'>

                <Link to={'/register'} className={`button solid ${props.active==='register' ? 'active' : ''}`}>
                    Start Writing
                </Link>

                {(currentUser) ? '' : (
                    <Link to={'/signin'} className={`button outlined ${props.active==='login' ? 'active' : ''} shazam`}>
                        Sign In
                    </Link>
                )}

            </div>
        </header>
    );
}

export default LandingNavigation;