import React, { useState } from 'react';
import axios from 'axios';
import {API_URL} from '../config';

const VerifyEmail = () => {

    const [email, setEmail] = useState('');

    const [loading, setLoading] = useState('');
    const [error, setError] = useState('');
    const [status, setStatus] = useState('');

    async function sendMail() {
        if(!loading) {
            setLoading(true);

            try {
    
                let res = axios.post(`${API_URL}/auth/reset-password`, { email: email }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                setStatus('complete');
            } catch (error) {
                if(error.response) {
                    setError(error.response.data.message);
                } else {
                    setError('Connection error');
                }
            }

            setLoading(false);
        }
    }

    return (
        <div className='intensity'>
            <div className='header'>
                <a href="/">
                    wordseveryday
                </a>
            </div>
            <div className='action-box'>
                <h4>Reset your Password</h4>
                {(status === 'complete') ? (
                    <>
                        <p>
                            Instructions should be sent to your inbox!
                        </p>
                    </>
                ) : (
                    <>
                        <p>
                            Please enter your email associated with your account and I'll send instructions there!
                        </p>
                        <input type="text" value={email} onChange={e=>setEmail(e.target.value)} />
                        <button disabled={loading || (email === '') || !(email.includes('@'))  || !(email.includes('.') || (email.length < 4))} onClick={e=>sendMail(e)}>
                            Send Instructions!
                        </button>
                    </>
                )}
                {(error) ? (
                    <div className='action-notification'>
                        {error}
                    </div>
                ) : ''}
            </div>
        </div>
    );
}

export default VerifyEmail;