import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL } from '../config';

const Feedback = () => {

    const [loading, setLoading] = useState(false);

    const [opened, setOpened] = useState(false);

    const [recommend, setRecommend] = useState('');
    const [features, setFeatures] = useState('');
    const [comments, setComments] = useState('');

    const [sent, setSent] = useState(false);

    const sendFeedback = async () => {
        setLoading(true);

        let payload = {
            recommend: recommend,
            features: features,
            comments: comments,
        }
        axios.post(`${API_URL}/auth/feedback`, payload)
        .then((res) => {
            setSent(true);
            localStorage.setItem('givenFeedback', 'true')
        })
        .catch((err) => {
            console.error(err);
            setLoading(false);
        })
    }

    const [firstTimer, setFirstTimer] = useState('');

    useEffect(() => {
        const validFeedback = localStorage.getItem('givenFeedback');

        if(validFeedback === 'true') {
            setFirstTimer(false);
        } else {
            setFirstTimer(true);
        }
    }, [])

    return firstTimer && (
        <>
            <div className={`feedback-card ${opened ? 'open' : ''}`}>
                <div className='feedback-inner'>
                    {(!sent) ? (
                        <>
                            <p>What change would make this site more usable?</p>
                            <textarea value={recommend} onChange={e=>setRecommend(e.target.value)}>
        
                            </textarea>
                            <p>Feature requests:</p>
                            <textarea value={features} onChange={e=>setFeatures(e.target.value)}>
        
                            </textarea>
                            <p>Your comments:</p>
                            <textarea value={comments} onChange={e=>setComments(e.target.value)}>
        
                            </textarea>
                            <button disabled={loading} onClick={e=>sendFeedback()}>
                                Send Feedback
                            </button>
                        </>
                    ) : (
                        <p>
                            Thank you for your feedback! I greatly appreciate it.
                        </p>
                    )}
                </div>
            </div>
            <div className='feedback-bubble' onClick={e=>setOpened(opened ? false : true)}>
                <h3>Provide Feedback</h3>
                <p>
                    {opened ? 'Close feedback dialog' : "I'd love to hear from you!"}
                </p>
            </div>
        </>
    )
}

export default Feedback;