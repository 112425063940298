import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../authentication/AuthContext';
import Navigation from '../components/Navigation';
import { API_URL } from '../config';

const Statistics = () => {

    const { currentUser, getUserData } = useAuthContext();
    const uid = getUserData().id;

    const [totalWords, setTotalWords] = useState(0);
    const [totalBadges, setTotalBadges] = useState(0);
    const [totalEntries, setTotalEntries] = useState(0);
    const [totalHours, setTotalHours] = useState(0);
    const [todayWords, setTodayWords] = useState(0);

    const [totalUsersAT, setTotalUsersAT] = useState(0);
    const [totalWordsAT, setTotalWordsAT] = useState(0);

    function getPersonalStats() {

        axios.get(`${API_URL}/user/${uid}`, {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': currentUser,
            }
        })
        .then((res) => {
            setTotalWords(res.data.wordCount.wordCount);
            setTotalBadges(res.data.badges.length);
        })
        .catch((error) => {
            console.error(error);
        });
    }

    function getTotalEntries() {

        axios.get(`${API_URL}/entry/${uid}/entries`, {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': currentUser,
            }
        }).then((res) => {
            let entries = res.data;

            let totalEntries = 0;

            for(let i = 0; i < entries.length; i++) {
                if(!(entries[i].entry === ' ' || entries[i].entry === '' || entries[i].entry.split(/[\s\t]+/).filter((item)=>(item && (item !== '\t'))).length < 2)) {
                    totalEntries += 1;
                }
            }

            if(entries.length > 0) {
                setTodayWords(entries[0].wordCount);
            }

            setTotalEntries(totalEntries);
        }).catch((error) => {
            console.error(error);
        })
    }

    function getGeneralStat() {
        axios.get(`${API_URL}/user/stats`)
        .then((res) => {
            setTotalUsersAT(res.data.users);
            setTotalWordsAT(res.data.words);
        })
        .catch((err) => {
            console.error('Error getting num users: ' + err);
        })
    }

    useEffect(() => {
        getPersonalStats();
        getTotalEntries();

        getGeneralStat();
    }, []);
    
    return (
        <div className='statistics-screen'>
            <Navigation />

            <div className='container-tight'>
                <h2>Numbers</h2>

                <div className='stats-container'>
                    <div className='col stats-personal'>
                        <h4>Personal Statistics</h4>
                        <div className='stat-item'>
                            <h5>{(totalWords + todayWords).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                            <p>Words Written to Date</p>
                        </div>
                        {/* <div className='stat-item'>
                            <h5>{totalHours.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                            <p>Hours in Writing</p>
                        </div> */}
                        <div className='stat-item'>
                            <h5>{totalEntries.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                            <p>Days with Entries</p>
                        </div>
                        <div className='stat-item'>
                            <h5>{totalBadges.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                            <p>Badges Collected</p>
                        </div>
                    </div>
                    {/* <div className='col stats-today'>
                        <h4>Today's Writers</h4>
                    </div> */}
                    <div className='col stats-monthly'>
                        <h4>Site-wide</h4>
                        <div className='stat-item'>
                            <h5>{totalUsersAT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                            <p>Total Members</p>
                        </div>
                        <div className='stat-item'>
                            <h5>{totalWordsAT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                            <p>Total Words Written</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Statistics;