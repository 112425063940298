import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../config';
import { useAuthContext } from '../authentication/AuthContext';
import LandingNavigation from '../components/LandingNavigation';
import LandingFooter from '../components/LandingFooter';

const Register = () => {

    const { currentUser, updateCurrentUser } = useAuthContext();
    const history = useHistory();

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [handle, setHandle] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    
    async function handleRegister(e) {
        e.preventDefault();

        if(!loading) {
            setLoading(true);
            setError('');

            if(confirmPassword === password) {

                try {
                    let res = await axios.post(`${API_URL}/auth/register`, {
                        fullName: fullName,
                        handle: handle,
                        email: email.toLowerCase(),
                        password: password
                    });
                    
                    history.push('/signin'); // refresh -- or TODO: show message to verify account
                } catch (error) {
                    if(error.response) {
                        setError(error.response.data.message);
                    } else {
                        setError('Cannot communicate with server');
                    }
                    setLoading(false);
                }
            } else {
                setError('Passwords do not match');
            }
        }
    }

    // Press enter key to login
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleRegister(e);
        }
    }
    
    // If user lands on the login page while already logged in
    useEffect(() => {
        if (currentUser) {
            history.push('/entry');
        }
    }, []);

    return !currentUser ? (
        <div className='register-screen'>
            <LandingNavigation active='register' />
            <main>
                <h2>Start your journey today.</h2>
                <label>Full Name</label>
                <input type="text" value={fullName} onChange={e=>setFullName(e.target.value)} onKeyDown={e=>handleKeyDown(e)} />

                <label>Account Handle</label>
                <input type="text" value={handle} onChange={e=>setHandle(e.target.value)} onKeyDown={e=>handleKeyDown(e)} />
                
                <label>Email</label>
                <input type="text" value={email} onChange={e=>setEmail(e.target.value)} onKeyDown={e=>handleKeyDown(e)} />

                <label>Password</label>
                <input type="password" value={password} onChange={e=>setPassword(e.target.value)} onKeyDown={e=>handleKeyDown(e)} />
                
                <label>Confirm Password</label>
                <input type="password" value={confirmPassword} onChange={e=>setConfirmPassword(e.target.value)} onKeyDown={e=>handleKeyDown(e)} />

                <div className='form-action'>
                    <button disabled={loading} onClick={e=>handleRegister(e)}>
                        Register
                    </button>
                    <Link to={'/signin'}>
                        Sign in instead
                    </Link>
                </div>
                
                {error ? (
                    <div className='form-notification error'>
                        {error}
                    </div>
                ) : ''}
            </main>

            <LandingFooter />
        </div>  
    ) : '';
}

export default Register;