import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL, STRIPE_KEY } from '../config';
import { useAuthContext } from '../authentication/AuthContext';
import Navigation from '../components/Navigation';

const EditProfile = () => {

    const { currentUser, getUserData } = useAuthContext();
    const uid = getUserData().id;

    const [ fullName, setFullName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ handle, setHandle ] = useState('');
    const [fullNameInit, setFullNameInit] = useState('');
    const [emailInit, setEmailInit] = useState('');
    const [handleInit, setHandleInit] = useState('');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [optionsLoading, setOptionsLoading] = useState(false);
    const [optionsSuccess, setOptionsSuccess] = useState('');
    const [optionsError, setOptionsError] = useState('');
    const [darkMode, setDarkMode] = useState(false);
    const [font, setFont] = useState('');
    const [background, setBackground] = useState('');
    const [darkModeInit, setDarkModeInit] = useState(false);
    const [fontInit, setFontInit] = useState('');
    const [backgroundInit, setBackgroundInit] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [status, setStatus] = useState('');

    async function loadProfile() {
        try {
            
            let res = await axios.get(`${API_URL}/user/${uid}`,{
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': currentUser,
                }
            });

            setFullName(res.data.fullName);
            setEmail(res.data.email);
            setHandle(res.data.handle);

            setFullNameInit(res.data.fullName);
            setEmailInit(res.data.email);
            setHandleInit(res.data.handle);

            // legacy check
            if(res.data.settings) {
                setDarkMode(res.data.settings.dark); // default: false
                setFont(res.data.settings.font); // default: times-new-romans | inter | inconsolata
                setBackground(res.data.settings.background); // default: #FFFFFF

                setDarkModeInit(res.data.settings.dark);
                setFontInit(res.data.settings.font);
                setBackgroundInit(res.data.settings.background);
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        loadProfile();
    }, []);


    async function handleSaveProfile(e) {
        e.preventDefault();

        if(!loading) {
            setLoading(true);
            setError('');
            setStatus('');

            try {
    
                let payload = {};
                
                if(fullNameInit !== fullName) {
                    payload['fullName'] = fullName;
                }
                if(emailInit !== email) {
                    payload['email'] = email;
                }
                if(handleInit !== handle) {
                    payload['handle'] = handle;
                }
                
                let res = await axios.patch(`${API_URL}/user/${uid}`, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': currentUser,
                    }
                });
                
                setStatus('Success! You may need to log out and sign back in for the changes to take effect.');

                setLoading(false);
    
            } catch (error) {
                if(error.response) {
                    setError(error.response.data.message);
                } else {
                    setError('Cannot communicate with server');
                }

                setLoading(false);
            }
        }

    }

    async function handleSavePassword(e) {
        e.preventDefault();

        if(!loading) {
            setLoading(true);
            setError('');
            setStatus('');

            try {
                let payload = {
                    password: password
                }

                let res = await axios.patch(`${API_URL}/user/${uid}`, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': currentUser,
                    }
                });

                setStatus('Success!');

                setPassword('');
                setConfirmPassword('');

                setLoading(false);
            } catch (error) {
                if(error.response) {
                    setError(error.response.data.message);
                } else {
                    setError('Cannot communicate with server');
                }

                setLoading(false);
            }
        }
    }

    async function handleSaveSettings(e) {
        e.preventDefault();

        let payload = {
            background: background,
            font: font,
            darkMode: darkMode,
        };

        console.log(payload);

        if(!optionsLoading) {
            setOptionsLoading(true);
            setOptionsError('');
            setOptionsSuccess('');

            axios.patch(`${API_URL}/user/${uid}`, {settings: payload}, {
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': currentUser,
                }
            }).then((res) => {
                setOptionsSuccess('Success!');
                setBackgroundInit(background);
                setFontInit(font);
                setDarkModeInit(darkMode);

                setOptionsLoading(false);

            }).catch((err) => {
                console.error(err);
                if(err.response) {
                    setOptionsError('Error! ' + err.response.data.message);
                } else {
                    setOptionsError('Cannot communicate with server');
                }
                setOptionsLoading(false);
            });
        }
    }
    

    return (
        <div className='edit-screen'>
            <Navigation />

            <div className='container-tight'>
                <h2>Edit Profile</h2>
                <label>Full Name:</label>
                <input type="text" value={fullName} onChange={e=>setFullName(e.target.value)} />
                <label>Account Handle:</label>
                <input type="text" value={handle} onChange={e=>setHandle(e.target.value)} />
                <label>Email:</label>
                <input type="text" value={email} onChange={e=>setEmail(e.target.value)} />
                <div className='form-action'>
                    <button onClick={e=>handleSaveProfile(e)} disabled={loading || !((fullNameInit !== fullName) || (emailInit !== email) || (handleInit !== handle))} >Save Profile Changes</button>
                </div>

                <h2 style={{paddingTop:60, paddingBottom: 0}}>Change Password</h2>
                <p style={{maxWidth: '60%', color: '#777777', paddingBottom: 20}}>
                    Passwords cannot be viewed. If you'd like to create a new password, enter one here. 
                </p>
                <label>New Password</label>
                <input type="password" value={password} onChange={e=>setPassword(e.target.value)} />
                <label>Confirm New Password</label>
                <input type="password" value={confirmPassword} onChange={e=>setConfirmPassword(e.target.value)} />
                <div className='form-action'>
                    <button onClick={e=>handleSavePassword(e)} disabled={loading || !(confirmPassword === password) || (password === '')} >Save New Password</button>
                </div>
                {error ? (
                    <div className='form-notification error'>
                        {error}
                    </div>
                ) : ''}
                {status ? (
                    <div className='form-notification info'>
                        {status}
                    </div>
                ) : ''}
                
                <h2 style={{paddingTop:60, paddingBottom: 0}}>Appearance</h2>
                <p style={{maxWidth: '60%', color: '#777777', paddingBottom: 20}}>
                    Personalize your writing experience!
                </p>
                <div className='option-block'>
                    <h4>Entry Page Background</h4>
                    <input type="color" name="background-colour" value={background} onChange={e=>setBackground(e.target.value)} />
                    {background}
                </div>
                <div className='option-block'>
                    <h4>Entry Page Font</h4>
                    <select value={font} onChange={e=>setFont(e.target.value)}>
                        <option value='times-new-romans'>Times New Romans</option>
                        <option value='inter'>Inter</option>
                        <option value='inconsolata'>Inconsolata</option>
                    </select>
                </div>
                <div className='option-block'>
                    <h4>Dark Mode</h4>
                    <div className='toggle-container'>
                        <div className={`custom-toggle ${darkMode ? 'right' : 'left'}`} onClick={e=>{setDarkMode(darkMode ? false : true)}}>
                            <div className={`toggle-slider`}>
                                <div className={`toggle-dot`}>
                                </div>
                            </div>
                        </div>
                        <div className='toggle-value'>
                            {darkMode ? 'Dark Mode' : 'Light Mode'}
                        </div>
                    </div>
                </div>
                <div className='form-action'>
                    <button onClick={e=>handleSaveSettings(e)} disabled={optionsLoading || (backgroundInit === background && fontInit === font && darkModeInit === darkMode)} >Save Appearance</button>
                </div>

                {optionsError ? (
                    <div className='form-notification error'>
                        {optionsError}
                    </div>
                ) : ''}
                {optionsSuccess ? (
                    <div className='form-notification info'>
                        {optionsSuccess}
                    </div>
                ) : ''}
                
                {/*
                <h2 style={{paddingTop:60, paddingBottom: 0}}>Account Subscription</h2>
                <button id="checkout">Subscribe</button>
                 <Elements stripe={promise}>
                    <CheckoutForm />
                </Elements> */}
            </div>
        </div>
    )
}

export default EditProfile;