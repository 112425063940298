import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { API_URL } from '../config';
import { useAuthContext } from '../authentication/AuthContext';

const VerifyEmail = () => {

    const { token } = useParams();
    
    const history = useHistory();

    const [status, setStatus] = useState();

    async function verifyAccount() {

        try {

            let res = axios.post(`${API_URL}/auth/verify/${token}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            // verification successful - sign in

            history.push('/signin');

        } catch (error) {
            if(error.response) {
                setStatus({
                    type: 'error',
                    message: error.response.data.message
                })
            } else {
                setStatus({
                    type: 'error',
                    message: 'Connection error'
                })
            }
        }
    }

    useEffect(() => {

        verifyAccount();

    }, [token]);

    return (
        <div className='intensity'>
            <div className='header'>
                <a href="/">
                    wordseveryday
                </a>
            </div>
            <div className='action-box'>
                <h4>Verify your account</h4>
                <p>
                    {status ? (
                        (status.type === 'error' && status.message) ||
                        (status.type === 'success' && status.message)
                    ) : ''}
                </p>
            </div>
        </div>
    );
}

export default VerifyEmail;