import React, { useState, useEffect } from 'react';
import { BADGES } from '../BADGES';
import LandingFooter from "../components/LandingFooter"
import LandingNavigation from "../components/LandingNavigation"

const Badges = () => {

    const [ displayBadges, setDisplayBadges ] = useState([]);

    useEffect(() => {

        let display_arr = [];

        for(let i = 0; i < BADGES.length; i++) {
            display_arr.push(
                <div className='badge'>
                    <img className='badge-image' src={`/badges/${BADGES[i].img}.png`} alt={BADGES[i].name} />
                    <div className='contents'>
                        <div className='badge-name'>
                            {BADGES[i].name}
                        </div>
                        <div className='badge-description'>
                            {BADGES[i].description}
                        </div>
                    </div>
                </div>
            )
        }

        setDisplayBadges(display_arr);

    }, [BADGES]);

    return (
        <div className='badges-screen'>
            <LandingNavigation />

            <div className='container-tight'>
                <h2 style={{paddingBottom:0}} >View Badges</h2>
                <p style={{paddingBottom: '5vh', lineHeight: '170%'}}>
                    Badges are a way to show your progression and mark significant milestones on your writing habit.
                    More are always being added! P.S. This list is not complete... we like surprises!
                </p>

                <div className='badges-container'>
                    {displayBadges}
                </div>
            </div>

            <LandingFooter />
        </div>
    )
}

export default Badges;