import LandingFooter from "../components/LandingFooter"
import LandingNavigation from "../components/LandingNavigation"
import axios from 'axios';
import { useEffect, useState } from "react";
import { API_URL } from '../config';
import { BADGES } from "../BADGES";
import Feedback from "../components/Feedback";

const Landing = () => {

    const [totalUsers, setTotalUsers] = useState(0);
    const [totalWords, setTotalWords] = useState(0);

    function getStat() {
        axios.get(`${API_URL}/user/stats`)
        .then((res) => {
            setTotalUsers(res.data.users);
            setTotalWords(res.data.words);
        })
        .catch((err) => {
            console.error('Error getting num users: ' + err);
        })
    }

    useEffect(() => {
        getStat();
    }, [])

    return (
        <div className='landing-screen'>
            <LandingNavigation />

            <Feedback />

            <div className='elephant'>
                <h1>
                    Write 750 private, 
                    unfiltered words,
                    every day.
                </h1>
                <h3>
                    Join {totalUsers ? totalUsers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''} others in this experience.
                </h3>
                <div className='elephant-actions'>
                    <a href="/register" className='button outlined'>
                        Start Writing
                    </a>
                    <a href="#streaks" className='button text'>
                        Learn More 
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>
                    </a>
                </div>
            </div>
            {/* <div className='dev-notes'>
                Hello! This notice exists because this site is not yet fully done. <strong>features that (should) work</strong>: entries, streaks, edit profile, register, verify profile, reset password; <strong>features that don't currently work</strong>: badges, statistics, subscription, mobile optimization. play around, test, actually use it, break things (but let me know when you do). Thanks!
            </div> 
            <div className='dev-notes'>
                Hello! This notice exists because this site very new: if you encounter any issues, have any feature ideas, or any other feedback, please don't hesitate to contact us @ <a href="mailto:support@wordseveryday.net">support@wordseveryday.net</a>!
            </div>*/}

            <div className='testimonials'>
                <div className='testimonial-inner'>
                    <div className='left'>
                        <h2>Express what is silently on your mind. You may surprise yourself.</h2>
                        <p>
                            wordseveryday is about creating a habit of writing—whatever it may be—by 
                            filling in 750 words every day. 
                            Nothing is published and every word is only seen by you. Use this site 
                            as your digital journal, a private twitter, or a forum to discover yourself.
                        </p>
                        <div className='stats'>
                            <div className='users'>
                                <h5>{totalUsers ? totalUsers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'Many'}</h5>
                                <h6>Private writers</h6>
                            </div>
                            <div className='words'>
                                <h5>{totalWords ? totalWords.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'Many'}</h5>
                                <h6>Words typed</h6>
                            </div>
                        </div>
                    </div>
                    <div className='right'>
                        <div className='testimone'>
                            <div className='name'>
                                Janet C.
                            </div>
                            <p>
                                Daily journaling is a cathartic 
                                activity. It's been granting me greater 
                                introspection recently.
                            </p>
                        </div>
                        <div className='testimone'>
                            <div className='name'>
                                Kevin R.
                            </div>
                            <p>
                                Keeping a streak is deceptively 
                                challenging, but the rewards
                                from writing consistently are more than worth it. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <section className='streaks' id="streaks">
                <div className='streaks-inner'>
                    <div className='copy'>
                        <h2>Stay accountable and consistent with daily streaks and badges.</h2>
                        <p>
                            The site keeps track of the days you’ve filled the 
                            daily 750 words goal with streaks, and you earn badges on 
                            special milestones.
                        </p>
                        <div className='badge-preview'>
                            <div className='badge'>
                                <img className='badge-image' src={`/badges/${BADGES[0].img}.png`} />
                                <div className='contents'>
                                    <div className='badge-name'>
                                        {BADGES[0].name}
                                    </div>
                                    <div className='badge-description'>
                                        {BADGES[0].description}
                                    </div>
                                </div>
                            </div>
                            <div className='badge'>
                                <img className='badge-image' src={`/badges/${BADGES[1].img}.png`} />
                                <div className='contents'>
                                    <div className='badge-name'>
                                        {BADGES[1].name}
                                    </div>
                                    <div className='badge-description'>
                                        {BADGES[1].description}
                                    </div>
                                </div>
                            </div>
                            <div className='badge'>
                                <img className='badge-image' src={`/badges/${BADGES[2].img}.png`} />
                                <div className='contents'>
                                    <div className='badge-name'>
                                        {BADGES[2].name}
                                    </div>
                                    <div className='badge-description'>
                                        {BADGES[2].description}
                                    </div>
                                </div>
                            </div>
                            <div className='badge'>
                                <img className='badge-image' src={`/badges/${BADGES[3].img}.png`} />
                                <div className='contents'>
                                    <div className='badge-name'>
                                        {BADGES[3].name}
                                    </div>
                                    <div className='badge-description'>
                                        {BADGES[3].description}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='action'>
                            <a href="/badges" className='button solid'>
                                View all Badges
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <LandingFooter />
        </div>
    )
}

export default Landing;