import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { useParams, Link } from 'react-router-dom';

const VerifyEmail = () => {

    const { token } = useParams();

    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [status, setStatus] = useState('');

    function sendCreateRequest(e) {
        e.preventDefault();

        if(!loading) {
            setError('');
            setStatus('');
            setLoading(true);

            axios.post(`${API_URL}/auth/create-password/${token}`, { password: password }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((res) => {

                setStatus('success');

            }).catch((error) => {
                if(error.response) {
                    setError(error.response.data.message);
                } else {
                    setError('Connection error');
                }
            });

        }
    }

    return (
        <div className='intensity'>
            <div className='header'>
                <a href="/">
                    wordseveryday
                </a>
            </div>
            <div className='action-box'>
                <h4>Reset your Password</h4>
                <p>
                    Please make sure your password is 4 or more characters!
                </p>
                <input type="text" value={password} onChange={e=>setPassword(e.target.value)} />
                <button disabled={loading || (password === '') || (password.length < 4)} onClick={e=>sendCreateRequest(e)}>
                    Create new password!
                </button>
                {(error) ? (
                    <div className='action-notification'>
                        {error}
                    </div>
                ) : ''}
                {(status === 'success') ? (
                    <div className='action-notification'>
                        You've successfully reset your password! You can sign in by <Link to={'/signin'}>clicking this link</Link>
                    </div>
                ) : ''}
            </div>
        </div>
    );
}

export default VerifyEmail;