// IMMUTABLES -- BADGES FULLY EXIST CLIENT-SIDE

const BADGES = [
    {
        name: 'A Planted Seed',
        description: 'Type your first word',
        img: '001',
    },
    {
        name: 'Beginning of a New Habit',
        description: 'Hold a 3 day streak',
        img: '002',
    },
    {
        name: 'Thousand Words Club',
        description: 'Type 1,000 words',
        img: '003',
    },
    {
        name: 'Ten Thousand Words Club',
        description: 'Type 10,000 words',
        img: '004',
    },
    {
        name: 'Hundred Thousand Words!',
        description: 'Type 100,000 words',
        img: '005',
    },
    {
        name: 'A Lot Of Words Club',
        description: 'Type 200,000 words',
        img: '006',
    },
    {
        name: 'A Month Streak',
        description: 'Hold a 31 day streak',
        img: '007',
    },
    {
        name: 'A Two-Month Streak',
        description: 'Hold a 62 day streak',
        img: '008',
    },
    {
        name: 'A Hundo Days',
        description: 'Hold a 100 day streak',
        img: '009',
    },
    {
        name: 'Two Hundo Days',
        description: 'Hold a 200 day streak',
        img: '010',
    },
    {
        name: 'A Year of Streaks',
        description: 'Hold a 365 day streak',
        img: '011',
    }

]

// Badge ID to index of badge (in main array above) so mongoDB only needs to store array of string rather than objects
const BADGE_SWITCHBOARD = {
    "B001": 0, // trigger: first save
    "B002": 1, // trigger: streak call
    "B003": 2, // trigger: entry save
    "B004": 3, // trigger: entry save
    "B005": 4, // trigger: entry save
    "B006": 5, // trigger: entry save
    "B007": 6, // trigger: streak call
    "B008": 7, // trigger: streak call
    "B009": 8, // trigger: streak call
    "B010": 9, // trigger: streak call
    "B011": 10, // trigger: streak call
}

export { BADGES, BADGE_SWITCHBOARD };