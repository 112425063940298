import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { useAuthContext } from '../authentication/AuthContext';
import Navigation from '../components/Navigation';
import { API_URL } from '../config';
import { BADGES, BADGE_SWITCHBOARD } from '../BADGES';

const Main = () => {
    
    const { currentUser, getUserData, logOut } = useAuthContext();
    const history = useHistory();

    const [today, setToday ] = useState(new Date());
    const [intervalID, setIntervalID] = useState('');
    const [timeLeft, setTimeLeft] = useState('');
    
    const uid = getUserData() ? getUserData().id : '';
    const email = getUserData() ? getUserData().email : '';
    const fullname = getUserData() ? getUserData().fullName : '';
    const handle = getUserData() ? getUserData().handle : '';

    const [streak, setStreak] = useState(0);
    const [wordCount, setWordCount] = useState('');
    
    const [ displayEntries, setDisplayEntries ] = useState([]);

    const [displayBadges, setDisplayBadges] = useState([]);
    const [badgesData, setBadgesData] = useState([]);

    const [dataLoading, setDataLoading] = useState(true);
    const [entries, setEntries] = useState([]);
    const [error, setError] = useState('');

    async function loadEntries() {
            
        try {
            let res = await axios.get(`${API_URL}/entry/${uid}/entries`, {
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': currentUser,
                }
            });

            setEntries(res.data);

            let entryOfToday = res.data.find((entry) => {return (entry.entryDate === `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`)});
            
            if(entryOfToday) {
                setWordCount(entryOfToday.wordCount);
            }

        } catch (error) {
            if(error.response) {
                setError(error.response.data.message);
            } else {
                setError('Cannot communicate with server');
            }
        }
    }

    async function loadStreak() {
    
        const todayISO = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        try {
            let res = await axios.get(`${API_URL}/user/${uid}/streak/${todayISO}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': currentUser,
                }
            });

            setStreak(res.data);
        } catch (error) {
            if(error.response) {
                setError(error.response.data.message);
            } else {
                setError('Cannot communicate with server');
            }
        }
    }

    function loadUserData() {

        axios.get(`${API_URL}/user/${uid}`, {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': currentUser,
            }
        })
        .then((res) => {
            setBadgesData(res.data.badges);
        })
        .catch((error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        if(dataLoading) {
            loadEntries();
            loadStreak();
            loadUserData();
            setDataLoading(false);
        }
    }, [])

    useEffect(() => {

        let display_array = []; 

        for(let i = 0; i < entries.length; i++) {
            if((entries[i].entryDate !== `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`) && !(entries[i].entry == ' ' || entries[i].entry == '' || entries[i].entry.split(/[\s\t]+/).filter((item)=>(item && (item !== '\t'))).length < 2)) {

                let date = entries[i].entryDate;

                display_array.push(
                    <div key={entries[i]._id} className='circle-container' >
                        <div onClick={e=>history.push(`/entries/${date}`)} className={`circle ${entries[i].completed ? ' filled' : ''}`}></div>
                        <div className={`label ${entries[i]._id}`}>
                            {date}
                        </div>
                    </div>
                );
            }
        }

        if(display_array.length === 0) return setDisplayEntries(<div style={{
            color:"#CCCCCC"
        }}>None, yet!</div>);

        setDisplayEntries(display_array);

    }, [entries]);

    useEffect(() => {
        let returnData = [];

        if(badgesData.length === 0) {
            setDisplayBadges(
                <div style={{
                    color:"#CCCCCC"
                }}>
                    No badges, yet!
                </div>
            );
        } else {

            for (let i = 0; i < badgesData.length; i++) {
                let badgeObj = BADGES[BADGE_SWITCHBOARD[badgesData[i]]];
    
                returnData.push(
                    <div key={badgeObj.name} className='mini-badge'>
                        <img src={`/badges/${badgeObj.img}.png`} />
                        <div className='mini-badge-hover'>
                            {badgeObj.name}
                        </div>
                    </div>
                );
            }

            setDisplayBadges(returnData);
        }
    }, [badgesData])

    
    function handleLogout() {
        logOut();
        history.push('/');
    }

    const tick = () => {
        setToday(new Date())
    }

    useEffect(() => {
        setIntervalID(setInterval(
            () => tick(),
            30000
        ));

        setTimeLeft(`${(23 - today.getHours()).toString().padStart(2, '0')}:${(60 - today.getMinutes()).toString().padStart(2, '0')}`);

        return () => clearInterval(intervalID);

    }, [today]);

    

    return (
        <> {/* .app-wrapper or something */}
        
            <Navigation />

            <div className='app-container'>
                <main className='home'>

                    <div className='left'>
                        <div className='streaks'>
                            <h2>{streak} day streak</h2>
                        </div>

                        <div className='today'>
                            <h3>Today</h3>
                            <div className='section-inner'>
                                <div className='column'>
                                    <div className='big-text'>{wordCount ? wordCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</div>
                                    <div className='med-text'>Words jotted</div>
                                </div>
                                <div className='column'>
                                    <div className='big-text'>{timeLeft}</div>
                                    <div className='med-text'>Hours left</div>
                                </div>
                            </div>

                            <Link to={'/entry'} className='inline-text-link'>
                                Today's Entry
                            </Link>
                        </div>

                        <div className='profile'>
                            <h3>Profile</h3>
                            <div className='section-inner'>
                                <div className='column'>
                                    <div className='med-text'>{fullname}</div>
                                    <div className='med-text'>{handle}</div>
                                    <div className='med-text'>{email}</div>
                                </div>
                            </div>

                            <div className='only-mobile'>
                                <Link to={'/numbers'} className='inline-text-link'>
                                    View Statistics
                                </Link>
                            </div>
                            <Link to={'/edit-profile'} className='inline-text-link'>
                                Edit profile
                            </Link>
                            <a className='inline-text-link' onClick={handleLogout}>
                                Log Out
                            </a>
                        </div>

                        <div className='badges'>
                            <h3>Badges</h3>
                            <div className='badges-inner'>
                                {displayBadges}
                            </div>
                            <Link to={'/badges'} className='inline-text-link'>
                                View all badges
                            </Link>
                        </div>
                        
                    </div>
                    
                    <div className='right'>
                        <h3>Past Entries</h3>
                        <div className='circologram'>
                            {displayEntries}
                        </div>
                    </div>

                </main>
            </div>
        </>
    );
}

export default Main;