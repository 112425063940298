import LandingFooter from "../components/LandingFooter"
import LandingNavigation from "../components/LandingNavigation"

const Contact = () => {

    return (
        <div className='badges-screen'>
            <LandingNavigation />

            <div className='container-tight'>
                <h2>Contact</h2>
            </div>

            <LandingFooter />
        </div>
    )
}

export default Contact;