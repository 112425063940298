import { Link } from 'react-router-dom';
import { useAuthContext } from '../authentication/AuthContext';

const LandingFooter = (props) => {

    const { currentUser } = useAuthContext();

    return (
        <footer>
            <div className='footer-inner'>
                <Link to="/" className='header-text'>
                    wordseveryday
                </Link>
                {(currentUser) ? '' : (
                    <Link to={'/signin'} className='minor'>
                        Sign In
                    </Link>
                )}
                <Link to={'/register'} className='minor'>
                    Start Writing
                </Link>
                <Link to={'/badges'} className='minor'>
                    View all Badges
                </Link>
                <a href="mailto:support@wordseveryday.net" className='minor'>
                    Contact
                </a>
            </div>
            <div className='barrel'>
                Made with ♥
            </div>
        </footer>
    );
}

export default LandingFooter;