import React, { useState, useRef, useEffect } from 'react';
import { Link, Prompt, useHistory } from 'react-router-dom';
import { DAY, MONTH } from '../utility';
import ReactQuill from 'react-quill'; 
import { Autosave } from "react-autosave";
import axios from 'axios';
import { API_URL } from '../config';
import { useAuthContext } from '../authentication/AuthContext';

const Entry = () => {

    const { currentUser, getUserData } = useAuthContext();
    const uid = getUserData().id;
    const history = useHistory();

    const [completeStatus, setCompleteStatus] = useState('');

    const [today, setToday] = useState(new Date());
    const todayISO = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const [intervalID, setIntervalID] = useState();

    // options
    const [font, setFont] = useState('');
    const [background, setBackground] = useState('');
    const [darkMode, setDarkMode] = useState('');

    // const utcdate = today.getUTCDate();
    // const utcmonth = today.getUTCMonth();
    // const utcyear = today.getUTCFullYear();
    // const utcdateobj = new Date(utcyear, utcmonth, utcdate);
    // console.log(utcdateobj);
    // console.log(utcdateobj.getTime());
    // const unix = new Date().getTime();
    // console.log(unix);

    const quillRef = useRef(null);

    const [doc, setDoc] = useState('');
    const [savedDoc, setSavedDoc] = useState('');
    const [needSalvation, setNeedSalvation] = useState(false);
    const [lastSave, setLastSave] = useState('');

    const [charCount, setCharCount] = useState(1);
    const [wordCount, setWordCount] = useState(1);
    const [selection, setSelection] = useState(0);

    const [timeLeft, setTimeLeft] = useState('');

    const [wOpened, setWOpened] = useState(false); // 'w'ord count dialog opened
    const [mOpened, setMOpened] = useState(false); // 'm'enu dialog opened

    const [dataLoading, setDataLoading] = useState(true);
    const [error, setError] = useState('');

    const [completeIsLoading, setCompleteIsLoading] = useState(false);

    async function saveCompletion() {
        let complete = (wordCount > 749);

        if(wordCount > 1) {

            if(!completeIsLoading) {
                console.log('save complete');
    
                setCompleteIsLoading(true);
    
                axios.patch(`${API_URL}/entry/${uid}/entry/${todayISO}`, {
                    completed: complete ? true : false,
                    time: new Date().getTime(),
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': currentUser,
                    }
                }).then((res) => {
                    setCompleteIsLoading(false);
                }).catch((err) => {
                    console.log(err);
    
                    setCompleteIsLoading(false);
                })
            }
        }
    }

    useEffect(() => {
        saveCompletion();
    }, [completeStatus]);

    useEffect(() => {
        if(wordCount > 749) {
            setCompleteStatus(true);
        } else {
            setCompleteStatus(false);
        }
    }, [wordCount]);
    
    async function loadEntry() {
        try {
            let res = await axios.get(`${API_URL}/entry/${uid}/entry/${todayISO}`,{
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': currentUser,
                }
            });

            setDoc(res.data.entry);
            setSavedDoc(res.data.entry);
            setDataLoading(false);
        } catch (error) {
            if(error.response) {
                setError(error.response.data.message);
            } else {
                setError('Cannot communicate with server');
            }
            setDataLoading(false);
        }
    }

    function saveEntry(data) {

        let wordCount = 0;

        if(quillRef.current) {
            const editor = quillRef.current.getEditor();
            const unprivilegedEditor = quillRef.current.makeUnprivilegedEditor(editor);

            wordCount = unprivilegedEditor.getText().split(/[\s\t]+/).filter((item)=>(item && (item !== '\t'))).length;
        }
        
        let payload = {
            entry: data,
            wordCount: wordCount,
            time: new Date().getTime(), 
        };

        axios.patch(`${API_URL}/entry/${uid}/entry/${todayISO}`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': currentUser,
            }
        }).then((res) => {

        }).catch((err) => {
            console.log(err);
        })
    }

    const saveDoc = React.useCallback((data) => {
        saveEntry(data);
        setLastSave(`Last Saved ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`);
        setSavedDoc(data);
    }, []);

    async function loadOptions() {
        axios.get(`${API_URL}/user/${uid}/options`, {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': currentUser,
            }
        }).then((res) => {
            setFont(res.data.font);
            setBackground(res.data.background);
            setDarkMode(res.data.darkMode);
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        if (todayISO && dataLoading) {
            loadEntry();
        }
        loadOptions();
    }, []);

    useEffect(() => {
        if(savedDoc !== doc) {
            setNeedSalvation(true);
        } else {
            setNeedSalvation(false);
        }

        if(quillRef.current) {
            const editor = quillRef.current.getEditor();
            const unprivilegedEditor = quillRef.current.makeUnprivilegedEditor(editor);

            setCharCount(unprivilegedEditor.getLength() - 1);
            setWordCount(unprivilegedEditor.getText().split(/[\s\t]+/).filter((item)=>(item && (item !== '\t'))).length);
            setSelection(unprivilegedEditor.getSelection());
        }

    }, [doc, savedDoc]);

    const tick = () => {
        setToday(new Date());
        // if(today.getHours() === 0 && today.getMinutes() === 0) {
        //     history.go(0);
        // }
    }

    useEffect(() => {
        setIntervalID(setInterval(
            () => tick(),
            30000
        ));

        setTimeLeft(`${(23 - today.getHours()).toString().padStart(2, '0')}:${(60 - today.getMinutes()).toString().padStart(2, '0')}`);

        return () => clearInterval(intervalID);

    }, [today]);


    const toggleDialog = (type) => {
        if (type === 'menu') {
            setMOpened(mOpened ? false : true)
            setWOpened(false);
        }
        if (type === 'word-count') {
            setWOpened(wOpened ? false : true)
            setMOpened(false);
        }
    }


    // prevent reload when yet to save
    useEffect(() => {
        if(needSalvation) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = undefined;
        }
    }, [needSalvation]);
    


  const [t, setT] = useState(false); // is save fucntion in timeout?
  const [l, setL] = useState(false); // loading
  const [f, setF] = useState(true); // is first load?

//   const [lt, setLt] = useState(0); // current start time
//   const [lwc, setLwc] = useState(0); // last word count

  const timeout = (delay, data) => {
    if(!t && !l) {
      return new Promise( res => setTimeout(() => res(data), delay) );
    } else {
      return new Promise((res, rej) => rej());
    }
  }

  useEffect( () => {
    async function doTimeout() {
    //   if(!t) {
    //     setLt(new Date().getTime());
    //     setLwc(wordCount);
    //   }
      setT(true);
      setL(true);
      
      await timeout(60000, { startTime: new Date().getTime(), wordCount: wordCount}).then(async (data) => {
            if(quillRef.current) {
                const editor = quillRef.current.getEditor();
                const unprivilegedEditor = quillRef.current.makeUnprivilegedEditor(editor);
                let twc = unprivilegedEditor.getText().split(/[\s\t]+/).filter((item)=>(item && (item !== '\t'))).length;
                let currentTime = new Date().getTime();

                let payload = {
                    id: uid,
                    date: '2021-6-5',
                    startTime: data.startTime,
                    duration: currentTime - data.startTime,
                    words: twc - data.wordCount,
                    totalWords: twc,
                };

                // // save
                try {
                    let res = axios.post(`${API_URL}/entry/wpm`, payload, {
                        headers: {
                            'Content-Type': 'application/json',
                            'auth-token': currentUser,
                        }
                    });
                } catch (error) {
                    console.error(error);
                }
                setL(false);
            }
            setT(false);

        }).catch((err) => {return;}); 
    }

    // first load is a pass
    if(!f) {
      doTimeout();
    } else {
      setF(false);
    }

  }, [charCount]);


    return (
        <div className={`entry-page ${darkMode ? 'darkmode' : ''}`} style={{
            background: background
        }}> 
            <div className='container-tight'>
                <h2>{`${DAY[today.getDay()]}, ${MONTH[today.getMonth()]} ${today.getDate()}, ${today.getFullYear()}`}</h2>
                
                <div className={`editor-wrapper font--${font}`}>
                        {(charCount === 0) ? (
                        <div className='prompter'>
                            Write something...
                        </div>
                        ) : ''}
                    <ReactQuill 
                      ref={quillRef}
                      formats={['bold', 'italic', 'underline']}
                      value={doc}
                      theme={null}
                      onChange={setDoc} />
                    
                    <Autosave data={doc} onSave={saveDoc} />

                    <Prompt
                        when={needSalvation}
                        message='You have unsaved changes, are you sure you want to leave?' />
                </div>
            </div>

            <div className='sheee'>
                
                {mOpened ? (
                    <div className='menu-dialog'>
                        <div className='menu-dialog-top'>
                            <Link to={'/home'} className='dialog-line link'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                Home
                            </Link>
                            <Link to={'/edit-profile'} className='dialog-line link'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                                Settings
                            </Link>
                        </div>
                        <div className='menu-dialog-bottom'>
                            <div className='dialog-line'>
                                <b>{timeLeft}</b> hours left today
                            </div>
                        </div>
                    </div>
                ) : ''}
                <div className={`more-menu ${mOpened ? 'opened' : ''}`} onClick={e=>toggleDialog('menu')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#CCCCCC" stroke="#CCCCCC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                </div>        


                {wOpened ? (
                    <div className='word-count-dialog'>
                        <div className='dialog-line'>
                            {wordCount} Words {(wordCount >= 700) ? '- Nice!' : ''}
                        </div>
                        <div className='dialog-line'>
                            {charCount} Characters
                        </div>
                        {(needSalvation) ? (
                            <div className='dialog-line'>
                                Saving...
                            </div>
                        ) : ((lastSave) ? (
                            <div className='dialog-line'>
                                {lastSave}
                            </div>
                        ) : '' // idk man I feel fucking dumb rn
                        )}

                    </div>
                ) : ''}
                <div className={`word-count ${wOpened ? 'opened' : ''}`} onClick={e=>toggleDialog('word-count')}>
                    {wordCount} Words
                </div>
            </div>
        </div>
    );
}

export default Entry;