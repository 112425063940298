import { Link, NavLink } from 'react-router-dom';

const Navigation = () => {

    return (
        <header>
            <Link to={'/home'} className='header-text subtle-link'>
                wordseveryday <sup>alpha</sup>
            </Link>
            <div className='header-action'>

                <NavLink to={'/numbers'} className='button outlined shazam' activeClassName='active'>
                    Numbers
                </NavLink>

                <Link to={'/entry'} className='button solid'>
                    Today's Entry
                </Link>

            </div>
        </header>
    )
}

export default Navigation;