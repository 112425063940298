import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";
import { AuthProvider, useAuthContext } from './authentication/AuthContext';

import Landing from './screens/Landing';
import Login from './screens/Login';
import Register from './screens/Register';
import Entry from './screens/Entry';
import Main from './screens/Main';
import PastEntry from './screens/PastEntry';
import EditProfile from './screens/EditProfile';
import VerifyEmail from './screens/VerifyEmail';
import ResetPassword from './screens/ResetPassword';
import CreatePassword from './screens/CreatePassword';
import Statistics from './screens/Statistics';
import Badges from './screens/Badges';
import Contact from './screens/Contact';

function App() {

  function AuthRoute({ component: Component, ...rest}) {
    const { currentUser } = useAuthContext();

    return (
      <Route
        {...rest}
          render={(props) => 
            (currentUser) ? (
              <Component {...props} />
            ) : (
              <Redirect to='/signin' />
            )
          }
        />
    )
  }

  return (
    <AuthProvider>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/signin" component={Login} />
        <Route exact path="/badges" component={Badges} />

        <AuthRoute exact path="/home" component={Main} />
        <AuthRoute exact path="/entry" component={Entry} />
        <AuthRoute exact path="/entries/:date" component={PastEntry} />
        <AuthRoute exact path="/edit-profile" component={EditProfile} />
        <AuthRoute exact path="/numbers" component={Statistics} />
        <AuthRoute exact path="/contact" component={Contact} />

        <Route exact path="/util/verify/:token" component={VerifyEmail} />
        <Route exact path="/util/reset-password" component={ResetPassword} />
        <Route exact path="/util/create-password/:token" component={CreatePassword} />

      </Switch>
    </AuthProvider>
  );
}

export default App;
